{
  "name": "annex-tracker-frontend",
  "version": "0.1.132",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --proxy-config proxy.config.json --host 0.0.0.0",
    "start:clean": "ng cache clean && npm run relink && npm run start",
    "prebuild": "npm version patch",
    "build": "ng build --configuration=development && npm run extractVersion",
    "analyze": "webpack-bundle-analyzer dist/annex-tracker-frontend/stats.json",
    "watch": "ng build --watch --configuration development",
    "deploy": "ng build && rsync -r --progress ./dist/annex-tracker-frontend/ literacy@85.215.239.231:/home/literacy/www/annex.lawyer",
    "test": "ng test",
    "relink": "npm unlink annex-tracker-backend && npm link annex-tracker-backend",
    "extractVersion": "node ./extractVersion.js"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.2.4",
    "@angular/common": "^17.2.4",
    "@angular/compiler": "^17.2.4",
    "@angular/core": "^17.2.4",
    "@angular/forms": "^17.2.4",
    "@angular/platform-browser": "^17.2.4",
    "@angular/platform-browser-dynamic": "^17.2.4",
    "@angular/router": "^17.2.4",
    "@feathersjs/authentication-client": "^5.0.2",
    "@feathersjs/socketio-client": "^5.0.2",
    "@fortawesome/angular-fontawesome": "~0.14.1",
    "@fortawesome/fontawesome-svg-core": "^6.5.1",
    "@fortawesome/pro-duotone-svg-icons": "^6.5.1",
    "@fortawesome/pro-light-svg-icons": "^6.5.1",
    "@fortawesome/pro-regular-svg-icons": "^6.5.1",
    "@fortawesome/pro-solid-svg-icons": "^6.5.1",
    "@fortawesome/pro-thin-svg-icons": "^6.5.1",
    "@fortawesome/sharp-solid-svg-icons": "^6.5.1",
    "@otplib/preset-browser": "^12.0.1",
    "@tailwindcss/container-queries": "^0.1.1",
    "@tailwindcss/forms": "^0.5.3",
    "@tailwindcss/line-clamp": "^0.4.2",
    "@tailwindcss/typography": "^0.5.9",
    "@types/diff": "^5.0.3",
    "@types/marked": "^6.0.0",
    "@types/qrcode": "^1.5.1",
    "@types/sanitize-html": "^2.9.0",
    "@types/trusted-types": "^2.0.3",
    "@types/zxcvbn": "^4.4.1",
    "@zip.js/zip.js": "^2.7.29",
    "chart.js": "^4.3.0",
    "chartjs-plugin-datalabels": "^2.2.0",
    "deepmerge": "^4.2.2",
    "diff": "^5.1.0",
    "fastest-levenshtein": "^1.0.16",
    "marked": "^12.0.2",
    "ng-otp-input": "^1.9.2",
    "ngx-extended-pdf-viewer": "file:../ngx-extended-pdf-viewer-19.0.6-next.1.tgz",
    "ngx-matomo-client": "^6.0.1",
    "ngx-toastr": "^18.0.0",
    "otplib": "^12.0.1",
    "qrcode": "^1.5.3",
    "rxjs": "~7.8.1",
    "sanitize-html": "^2.11.0",
    "scroll-into-view-if-needed": "^3.0.4",
    "slugify": "^1.6.6",
    "socket.io-client": "^4.6.1",
    "tailwind-scrollbar": "^3.0.0",
    "timeago.js": "^4.0.2",
    "ts-node": "^10.9.1",
    "tslib": "^2.3.0",
    "webpack-bundle-analyzer": "^4.8.0",
    "zone.js": "~0.14.3",
    "zxcvbn3": "^0.1.1"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.3",
    "@angular/cli": "~17.2.3",
    "@angular/compiler-cli": "^17.2.4",
    "@types/jasmine": "~4.3.0",
    "jasmine-core": "~4.5.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.0"
  }
}
